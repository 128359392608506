import { Injectable } from '@angular/core';
import { ReportesEntity } from '../models/ReportesEntity';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';
import 'rxjs/add/operator/map';
@Injectable({
  providedIn: 'root'
})
export class PlantillaBremboService {

  constructor(private http: HttpClient, private config: PeticionesService) { }

    getAduana(): string[]
    {
      const aduanas: string [] = [ '240',
        '270',
        '800'
      ];
      return aduanas;
  }
  getValorInicial(): ReportesEntity
  {

    const date: Date = new Date();
    const ParamsReport = new ReportesEntity();
    ParamsReport.ClaveCliente = 'BREMBO';
    ParamsReport.aduana = '240';
    ParamsReport.patente = '3649';
    ParamsReport.tipoOperacion = '';
    ParamsReport.FechaInicial = date;
    ParamsReport.FechaFinal = date;
    return ParamsReport;
  }
  getPLantillaBrembo(ParametrosReporte: ReportesEntity): Observable<any>
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    const json = JSON.stringify(ParametrosReporte);
    return this.http.post(this.config.url + '/Reporte/brembo/selImportacionBrembo', json, httpOptions).pipe(map(data => data));
  }
  download( filename: string): Observable<Blob> {
    return this.http.get(this.config.url + '/Reporte/brembo/Descarga?fileName=' + filename,{
      responseType: 'blob'
    });
  }
}
