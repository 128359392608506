import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { PeticionesService} from './models/config-web-api';
import { LoginService } from './_services/login.service';
import { ServExtService}  from './_services/serv-ext.service';
import { FormsModule, ReactiveFormsModule,FormControl } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SOIAImpoComponent } from './pages/soiaimpo/soiaimpo.component';
import { SOIAExpoComponent } from './pages/soiaexpo/soiaexpo.component';
import { CredencialesEntity } from './models/credenciales';
import { ReportesEntity } from './models/ReportesEntity';
import {
  DxButtonModule,
  DxDataGridModule,
  DxLoadPanelModule,
  DxMenuModule,
  DxFileManagerModule,
  DxTabPanelModule,
  DxToolbarModule,
  DxDropDownButtonModule,
  DxFormModule,
  DxSelectBoxModule,
  DxDrawerModule,
    DxListModule,DxPopupModule,DxTabsModule,DxTextAreaModule,DxRadioGroupModule,DxAutocompleteModule,DxToastModule
} from 'devextreme-angular';
import { HomeComponent } from './pages/home/home.component';
import { ExpedientesComponent } from './pages/expedientes/expedientes.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { PlantillaBallComponent } from './pages/reportes/plantilla-ball/plantilla-ball.component';
import { NavbarComponent } from './pages/navbar/navbar.component';
import { PlantillaBremboComponent } from './pages/reportes/plantilla-brembo/plantilla-brembo.component';
import { BallConsolidadoComponent } from './pages/ball-consolidado/ball-consolidado.component';
import { ServExtComponent } from './pages/serv-ext/serv-ext.component';
import { ServRptComponent } from './pages/reportes/serv-rpt/serv-rpt.component';
import { CommonModule } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';

import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SOIAImpoComponent,
    SOIAExpoComponent,
    HomeComponent,
    ExpedientesComponent,
    UsuariosComponent,
    PlantillaBallComponent,
    NavbarComponent,
    PlantillaBremboComponent,
    BallConsolidadoComponent,
    ServExtComponent,
    ServRptComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DxDataGridModule,
    DxFileManagerModule,
    DxLoadPanelModule,
    DxTabPanelModule,
    DxMenuModule,
    DxButtonModule,
    DxToolbarModule,
    DxDropDownButtonModule,
    DxFormModule,
    DxSelectBoxModule,
    DxDrawerModule,
    DxListModule,
    DxToolbarModule,
    DxPopupModule,
    DxTabsModule,
    DxTextAreaModule,
    DxRadioGroupModule,
    DxAutocompleteModule,
    DxToastModule,
    ToastNoAnimationModule.forRoot(),
    NgxLoadingModule.forRoot({})
  ],
  providers: [PeticionesService, LoginService, CredencialesEntity, ReportesEntity ,ServExtService],
  bootstrap: [AppComponent]
})
export class AppModule { }
