import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';
import { CredencialesEntity } from '../models/credenciales';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class SoiaimpoService {

  constructor(private http: HttpClient,private config:PeticionesService) { }

  getInfoSoaiImportacion(): Observable<any>{
    return this.http.get(this.config.url+"SOIA/Importacion");
  }
}
