import { MenuEntity } from "./Menu";

export  class UsuariosEntity {
    usuid: number;
    usunombre: string;
    usuusuario: string;
    usupassword: string;
    usucorreo: string;
    usuingreso: string;
    usuexpiracion: string;
    activo: boolean;
    TipUsuId: Number;
    GruId: number;
    token?: string;
    Menu: Array<MenuEntity>
}
