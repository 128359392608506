import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';

//import { environment } from '@environments/environment';
import { UsuariosEntity } from '../models/Usuarios';
import { CredencialesEntity} from '../models/credenciales';
import { IfStmt } from '@angular/compiler';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<UsuariosEntity>;
    public currentUser: Observable<UsuariosEntity>;

    constructor(private http: HttpClient,private config: PeticionesService) {
        this.currentUserSubject = new BehaviorSubject<UsuariosEntity>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): UsuariosEntity {
        return this.currentUserSubject.value;
    }

    login(credenciales: CredencialesEntity) {
        var json = JSON.stringify(credenciales);
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };
        return this.http.post<any>(`${this.config.url}/Usuarios/Login`, json,httpOptions)
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //console.log(user);
                
                if(user.Status){
                    localStorage.setItem('currentUser', JSON.stringify(user.Data));
                    this.currentUserSubject.next(user);
                }
               
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}