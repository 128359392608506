import { NgModule, Component, OnInit,enableProdMode,ViewChild,Injectable, ɵCompiler_compileModuleSync__POST_R3__  } from '@angular/core';
import { ServExtService } from '../../_services/serv-ext.service'
import Swal, {SweetAlertIcon} from 'sweetalert2';
import {FormCuenta, ServicioExtra, TabsConten} from '../../models/ServicioExtra';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {DxDataGridModule, DxDataGridComponent } from 'devextreme-angular';
import {  AuthenticationService } from './../../_services/authentication.service';
import { UsuariosEntity } from './../../models/Usuarios';
import { ToastrService} from 'ngx-toastr';
import { LoadingService } from './../../_services/loading.service';
import {Observable} from 'rxjs-compat';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-serv-ext',
  templateUrl: './serv-ext.component.html',
  styleUrls: ['./serv-ext.component.css']
})

@Injectable({providedIn: 'root'})

export class ServExtComponent implements OnInit { 
  @ViewChild(DxDataGridComponent, {static: false}) dataGrid: DxDataGridComponent;

  loadingStatus$: Observable<boolean>;
  DataServicioExtra: Array<ServicioExtra>[];
  DataSrv: Array<ServicioExtra> = [];
  Tabs: Array<TabsConten>= [];
  SerExtraSelect : ServicioExtra;
  popupVisible = false;
  popupVisibleFacturados = false;
  checkBoxesMode: string;
  idSelectet : number;
  isVisible: boolean = false;
  public isLoadPanelVisible = false;
  currentUser: UsuariosEntity;
  popWarning: boolean = false;
  fechaUltima: string;
  

  title_PorFactura: string;
  title_Facturados: string;
  title_PorLiberar: string;

  isVisible_PorFacturas: boolean = false;
  isVisible_Facturados: boolean = false;
  isVisible_PorLiberar: boolean = false;

  source_PorFactura : Array<ServicioExtra>;
  source_Facturados : Array<ServicioExtra>;
  source_PorLiberar : Array<ServicioExtra>;

  isVisibleGrid_PorFacturar : boolean = true;
  isVisibleGrid_Facturados : boolean = false;
  isVisibleGrid_PorLiberar : boolean = false;

  FormFac : FormCuenta = {
      id : 0, 
      formData : {
        Id:0,
        Facturas:"",
        Caja: "",
        Proveedor:"",
        Pedimento:"",
        Trafico:"",
        TipoOperacion:0,
        USD:0,
        MN:0,
        FechaDespacho:null,
        FacturasServExt:"",
        CuentaAmericana:"",
        Observaciones:"",
        IdInserto:0,
        FechaInserto: null,
        IdModifico:0,
        FechaModifico:null,
        Cliente:"",
        EstSerExtId:0,
        TipoOpe:"",
        FechaLlegada:null,
        Comentarios:"",
        Estatus:""

    }
  };
  PorFacturar :TabsConten = { texto :"Por Facturar",content:null  };
  Facturado: TabsConten =  { texto :"Facturadas",content:null  };
  PorLiberar: TabsConten =  { texto :"Por Liberar",content:null  };
  constructor(private _SerExt : ServExtService,  private authenticationService: AuthenticationService,private toastr: ToastrService,private loadingService: LoadingService) 
  {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.loadingService.show();
    this.selPorLiberar();
    this.selPorFacturar();
    this.selFacturados();

    //this.loadingService.hide();
  }

  ngOnInit() {
   this.UltimaActualizacion();
   this.loadingStatus$ = this.loadingService.listenLoading();
 
  }
  //Temporal
  showAlert(){
    this.loadingService.show();
  }
  //END Temporal
  selectSingleRow (key, preserve) {
      if (!this.dataGrid.instance.isRowSelected(key)) {
          this.dataGrid.instance.selectRows([key], preserve);
      }
  }
  showError(titleMsg: string, textMsg: string, iconMsg: SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
  showConsole(e){
    this.FormFac.id = this.currentUser.usuid;
    this.FormFac.formData.Id = e.values[1];

    this.FormFac.formData.Cliente =  e.values[0];
    this.FormFac.formData.Facturas = e.values[3];
    this.FormFac.formData.Caja = e.values[4]
    this.FormFac.formData.Proveedor =  e.values[5];
    this.FormFac.formData.Pedimento = e.values[6];
    this.FormFac.formData.TipoOpe = "0";
    this.FormFac.formData.TipoOperacion=0;
    this.FormFac.formData.Trafico = e.values[8];
    this.FormFac.formData.FechaDespacho = e.values[9];
    this.FormFac.formData.FacturasServExt = e.values[11];
    this.FormFac.formData.CuentaAmericana = e.values[12];
    this.FormFac.formData.Observaciones = e.values[13];
    this.FormFac.formData.EstSerExtId = 2;
    this.FormFac.formData.Comentarios = e.values[15];

    if(e.values[2] == 1){
      this.FormFac.formData.FacturasServExt = "";
      this.popupVisible =true;
      
    }
    if(e.values[2] == 2){
      this.popupVisibleFacturados = true;
    }
   
  }
  showInfo(objServ){
    this.idSelectet = objServ.Id;
    this._SerExt.ActualizarStatus(objServ,this.currentUser.usuid).subscribe(
      result =>{
        if(result.Status){
        }else{
        }
      }
    );
    this.popupVisible = true;
  }
  onContentReadyHandler (e) {
    // Selects the first visible row
    e.component.selectRowsByIndexes([0]);
  }
  buttonOptions: any = {
    text: "Guardar",
    type: "success",
    useSubmitBehavior: true,
    icon:"todo"
  }
  buttonOptionsCancelar: any = {
    text: "Borrar",
    type: "error",
    useSubmitBehavior: false,
    icon:"close"
  }
  selPorFacturar(){
    this._SerExt.getPorFacturar().subscribe(
      result => {
        if(result.Status)
        {
            this.source_PorFactura = result.Data[0];
            // var i = 0;
            // this.PorFacturar.texto = "Por Facturar ("+ this.abrevCantidades(this.DataServicioExtra[0].length)+")";
            // this.PorFacturar.content = this.DataServicioExtra[0];
            // this.Tabs[0] = this.PorFacturar;
            this.source_PorFactura.forEach((item) => {
              if(item.TipoOperacion == 1){
                item.TipoOpe = "I";
              }
              else{
                item.TipoOpe = "E";
              }
            });
            this.title_PorFactura =  "Por Facturar ("+ this.abrevCantidades(this.source_PorFactura.length)+")";
            this.isVisible_PorFacturas = true;

            this.loadingService.hide();
        }else
        {
          this.popupVisibleFacturados = false;
          this.popupVisible =false;
          this.showError('ERROR',result.Message,'error');
        }
      }
    );
  
  }
  selFacturados(){
    this._SerExt.getFacturados().subscribe(
      result => {
        if(result.Status)
        {
            this.source_Facturados = result.Data[0];
            this.source_Facturados.forEach((item) => {
              if(item.TipoOperacion == 1){
                item.TipoOpe = "I";
              }
              else{
                item.TipoOpe = "E";
              }
            });
            // var i = 0;
            // this.Facturado.texto = "Facturados ("+ this.abrevCantidades(this.DataServicioExtra[0].length)+")";
            // this.Facturado.content = this.DataServicioExtra[0];
            // this.Tabs[1] = this.Facturado;
            // this.loadingService.hide();


            this.title_Facturados  = "Facturados ("+ this.abrevCantidades(this.source_Facturados.length)+")";
            this.isVisible_Facturados = true;

            
        }else
        {
          this.popupVisibleFacturados = false;
          this.popupVisible =false;
          this.showError('ERROR',result.Message,'error');
        }
      }
    );
  
  }
  selPorLiberar(){
    this._SerExt.getPorLiberar().subscribe(
      result => {
        if(result.Status)
        {
            this.source_PorLiberar = result.Data[0];

            this.source_PorLiberar.forEach((item) => {
              if(item.TipoOperacion == 1){
                item.TipoOpe = "I";
              }
              else{
                item.TipoOpe = "E";
              }
            });
            // var i = 0;
            // this.PorLiberar.texto = "Por Liberar ("+ this.abrevCantidades(this.DataServicioExtra[0].length)+")";
            // this.PorLiberar.content = this.DataServicioExtra[0];
            // this.Tabs[2] = this.PorLiberar;
            // this.loadingService.hide();

            this.title_PorLiberar ="Por Liberar ("+ this.abrevCantidades(this.source_PorLiberar.length)+")";
            this.isVisible_PorLiberar = true;
    
        }else
        {
          this.popupVisibleFacturados = false;
          this.popupVisible =false;
          this.showError('ERROR',result.Message,'error');
        }
      }
    );
  
  }
 abrevCantidades(cantidad){
   
    var cadCant,mensaje;


    if(cantidad < 1000){
      mensaje =  cantidad;
    }
    if(cantidad >=  1000){
      cadCant = cantidad/1000;
      mensaje = cadCant.toFixed(1).toString()+" K";
    }
    return mensaje 

  }

  OnClickActualizar(){
    this.loadingService.show();
    this.Actualizar();
    //this.loadingService.hide();
  }

  Actualizar(){
   
    this._SerExt.ActualizarInfo().subscribe(
      result =>{
        if(result.Status){
          this.UltimaActualizacion();
          
          this.toastr.success('Proceso Terminado', '');
          this.loadingService.hide();
        }
      }
    );
  }
  
  UltimaActualizacion(){
    this._SerExt.UltimaActualizacion().subscribe(
       result =>{
        this.fechaUltima = result.Message;
        //this.loadingService.hide();
       }
    );
  }
  hidePop(){


    this.FormFac.formData.EstSerExtId=1;
    this.FormFac.formData.FacturasServExt = "";
    this.FormFac.formData.CuentaAmericana = "";

    this._SerExt.ActualizarStatus(this.FormFac.formData,this.FormFac.id).subscribe(
      result => {
        if(result.Status){
          this.selPorFacturar();
          this.selFacturados();
          this.popWarning=false;
          this.popupVisible=false;
        }else{
          this.popupVisible = false;
          this.popupVisibleFacturados = false;
          this.showError('ERROR',result.Message,'error');
        }
    });
    
  }
 
  onSubmitForm(e){
    if(this.FormFac.formData.FacturasServExt != "" || this.FormFac.formData.CuentaAmericana != ""){
      this._SerExt.ActualizarStatus(this.FormFac.formData,this.FormFac.id).subscribe(
        result => {
          if(result.Status){
            this.selPorFacturar();
            
            this.popupVisible = false;
            this.popupVisibleFacturados = false;
            this.selFacturados();
            this.selPorLiberar();
          }else{
            this.popupVisible = false;
            this.popupVisibleFacturados = false;
            this.showError('ERROR',result.Message,'error');
          }
      });
    }
    else{
      this.popupVisibleFacturados = false;
      this.popupVisible =false;
      this.showError('Campo vacio',"El numero de cuenta es un dato necesario.",'error');
    }
    e.preventDefault();
  }
  onSubmitForm2(){
    if(this.FormFac.formData.FacturasServExt != "" || this.FormFac.formData.CuentaAmericana != ""){
      this._SerExt.ActualizarStatus(this.FormFac.formData,this.FormFac.id).subscribe(
        result => {
          if(result.Status){
            this.selFacturados();
            this.popupVisible = false;
            this.popupVisibleFacturados = false;
            this.selPorFacturar();
            this.selPorLiberar();
          }else{
            this.popupVisible = false;
            this.popupVisibleFacturados = false;
            
            this.showError('ERROR',result.Message,'error');
          }
      });
    }
    else{
      this.popupVisibleFacturados = false;
      this.popupVisible =false;
      this.showError('Campo vacio',"El numero de cuenta es un dato necesario.",'error');
    }
  }
  onShowWarning(){
    this.popWarning = true;
    this.popupVisibleFacturados = false;
  }
  onCancelWarning(){
    this.popWarning =false;
  }

  showGrids(i){
    if(i == 1){
      this.isVisibleGrid_PorFacturar = true;
      this.isVisibleGrid_Facturados = false;
      this.isVisibleGrid_PorLiberar = false;
    }
    if(i == 2){
      this.isVisibleGrid_PorFacturar = false;
      this.isVisibleGrid_Facturados = true;
      this.isVisibleGrid_PorLiberar = false;
    }
    if(i == 3){
      this.isVisibleGrid_PorFacturar = false;
      this.isVisibleGrid_Facturados = false;
      this.isVisibleGrid_PorLiberar = true;
    }
  }
}
