import { Injectable, ɵConsole } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';
import { CredencialesEntity } from '../models/credenciales';
import 'rxjs/add/operator/map';

@Injectable()
export class LoginService {
  constructor(private http: HttpClient,private config:PeticionesService){}

  getUsuarios(): Observable<any>{
    return this.http.get(this.config.url+"/Usuarios/GetAll");
  }
  // selAgregarCruce(jsonString:string): Observable<any>{

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type':  'application/json'
  //     })
  //   };
  //   return this.http.post(this.config.url+'/cruces/add', jsonString, httpOptions).pipe(map(data => data));
  // }
  selInicioSesion(credenciales: CredencialesEntity): Observable<any>{

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    var json = JSON.stringify(credenciales);
    return this.http.post(this.config.url+'/Usuarios/Login', json, httpOptions).pipe(map(data => data));
  }
}
