import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { saveAs } from 'file-saver';
import { BallConsolidadoService } from '../../_services/ball-consolidado.service';
import { BallConsolidado } from '../../models/BallConsolidado';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { DxSelectBoxModule,
  DxTextAreaModule,
  DxFormModule,
  DxFormComponent } from 'devextreme-angular';
import { ReporteBall } from '../../models/ReportesEntity';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-ball-consolidado',
  templateUrl: './ball-consolidado.component.html',
  styleUrls: ['./ball-consolidado.component.css'],
  providers: [BallConsolidadoService,DatePipe]
})
export class BallConsolidadoComponent implements OnInit {

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent;
  searchForm: ReporteBall;
  dataSource: any;
  isLoadPanelVisible = false;
  datePipe:DatePipe;
  constructor(private _ballReport: BallConsolidadoService,public datepipe: DatePipe) { 

    this.searchForm = this._ballReport.getValorInicial();
  }

  ngOnInit() {
  }
  onFormGetValues(e) {
    this.searchForm = e.component.option('formData');
  }

  onFormSubmit = function(e){
    this.isLoadPanelVisible = true;
    if(!this.searchForm.Accepted){
      if(this.searchForm.pedimento  === ''){
        this.showError('Error', 'El pedimento, es un dato necesario.', 'error');
     }
    }
     

     let formatDateInicial = this.datepipe.transform( this.searchForm.FechaInicial,'MM/dd/yyyy');
     //console.log(formatDateInicial);
     let formatDateFinal =  this.datepipe.transform( this.searchForm.FechaFinal,'MM/dd/yyyy');
    
     this._ballReport.getDataPorPedimento(this.searchForm.pedimento,formatDateInicial, formatDateFinal, this.searchForm.Accepted).subscribe(
       result => {
         if(result.Status){
              this.dataSource = result.Data;
              this.isLoadPanelVisible = false;
         }else
            {
              this.showError('Error 4006!', "Error al consultar.", 'error');
              this.isLoadPanelVisible = false;
              this.isData = false;
              return;
            }
       }
     )
  }
  export =  function() {
    this.isLoadPanelVisible = true;

    let nameFile = "";
    if(this.searchForm.Accepted){
      nameFile="Reporte Consolidado";
    }else{
      nameFile= "Reporte consolidado "+this.searchForm.pedimento;
    }
    let formatDateInicial = this.datepipe.transform( this.searchForm.FechaInicial,'MM/dd/yyyy');
    let formatDateFinal =  this.datepipe.transform(this.searchForm.FechaFinal,'MM/dd/yyyy');

    this._ballReport.download(this.searchForm.pedimento,formatDateInicial, formatDateFinal, this.searchForm.Accepted)
             .subscribe(blob => 
              saveAs(blob, nameFile+".xlsx")
    );
    this.isLoadPanelVisible = false;
 
  }
  showError(titleMsg: string, textMsg: string, iconMsg: SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
}
