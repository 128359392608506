import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs'
import {map} from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';
import 'rxjs/add/operator/map';
@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(private http: HttpClient,private config:PeticionesService) { }

  getClientesActivos(): Observable<any>{
    return this.http.get(this.config.url+"/Clientes/SelClientesActivos");
  }
}
