export  class ReportesEntity {
    FechaInicial:Date;
    FechaFinal:Date;
    ClaveCliente: string;
    aduana: string;
    tipoOperacion: string;
    patente: string;
}

export class ReporteBall{
    pedimento:string;
    FechaInicial:Date;
    FechaFinal:Date;
    Accepted:boolean;
}