import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class PeticionesService
{
  public url: string;
  private serverAddress: any = {
    // Debug
    deb: 'http://10.10.10.23:5069/api/api/',
    // Desarrollo
    dev: 'https://sit.tramitaciones.com/api/api/',
    // Preproducción
    pre: 'http://10.10.10.23:9111/api/api/',
    // Producción
    pro: ''
  };


  constructor(private http: HttpClient)
  {
    this.url = this.serverAddress.pre;
  }
}
