import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common' ;
import { ServicioExtra } from '../models/ServicioExtra';
import { PeticionesService } from '../models/config-web-api';
import { ReportesBodega } from '../models/ReportesBodega';
@Injectable({
  providedIn: 'root'
})
export class ServExtService {

  constructor(private http: HttpClient, private config: PeticionesService) { }


  ActualizarInfo(): Observable<any>{

    return this.http.get(this.config.url + 'selServiciosExtraordinarios/ActualizarInfo').pipe(map(data => data));
  }
  getPorFacturar(): Observable<any>
  {
    
    return this.http.get(this.config.url+'selServiciosExtraordinarios/PorFacturar').pipe(map(data => data));
  
  }

  getFacturados(): Observable<any>
  {
    
    return this.http.get(this.config.url+'selServiciosExtraordinarios/Facturadas').pipe(map(data => data));
  
  }

  getPorLiberar(): Observable<any>
  {
    
    return this.http.get(this.config.url+'selServiciosExtraordinarios/PorLiberar').pipe(map(data => data));
  
  }

  UltimaActualizacion(): Observable<any>
  {
    return this.http.get(this.config.url+'selServiciosExtraordinarios/UltimaUPD').pipe(map(data => data));
  }
  ActualizarStatus(srvExt: ServicioExtra, idModifica:number): Observable<any>{

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    const json = JSON.stringify(srvExt);
    return this.http.post(this.config.url + 'selServiciosExtraordinarios/Actualizar?usuModifica='+idModifica, json, httpOptions).pipe(map(data => data));
  }
  getValorReporte() :  ReportesBodega{

    let dt  = new Date().toDateString();  

    console.log(dt)
    let ParamsReport = new ReportesBodega();
    ParamsReport.FechaInicial = new Date(dt);
    ParamsReport.FechaFinal =  new Date(dt);
    ParamsReport.Trafico = "";
    ParamsReport.TipoBusqueda=3;
    return ParamsReport;
  }
  selReporte(srvExt: ReportesBodega): Observable<any>{

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    const json = JSON.stringify(srvExt);
    console.log(json);
    return this.http.post(this.config.url + 'selServiciosExtraordinarios/Reporte', json, httpOptions).pipe(map(data => data));
  }
  selTrafico(Trafico: string): Observable<any>{

    return this.http.get(this.config.url + 'selServiciosExtraordinarios/selTrafico?Trafico='+Trafico).pipe(map(data => data));
  }
  selTraficos(): Observable<any>{

    return this.http.get(this.config.url + 'selServiciosExtraordinarios/Traficos').pipe(map(data => data));
  }
  download(srvExt: ReportesBodega): Observable<any>{

  
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const json = JSON.stringify(srvExt);
    return this.http.post(this.config.url + 'selServiciosExtraordinarios/DescargarReporte', json, {headers,responseType:'blob'}).pipe(map(data => data));
  }
}
