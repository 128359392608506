import { Injectable, Inject } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';
import 'rxjs/add/operator/map';
import { ReportesEntity } from '../models/ReportesEntity';
import { SAVER, Saver } from '../_services/saverball.providers';
import { download, Download } from '../models/download';
@Injectable({
  providedIn: 'root'
})
export class PlantillaBallService {

  constructor(private http: HttpClient, private config: PeticionesService) { }

  getReportesImportacion(ParametrosReporte: ReportesEntity): Observable<any>
  {
    const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  const json = JSON.stringify(ParametrosReporte);
  return this.http.post(this.config.url + '/Reporte/ball/selImportacionBall', json, httpOptions).pipe(map(data => data));

  }
  getReportesExportacion(ParametrosReporte : ReportesEntity): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    var json = JSON.stringify(ParametrosReporte);
    
    return this.http.post(this.config.url+'/Reporte/ball/selExportacionBall', json, httpOptions).pipe(map(data => data));
  
  }
  getBallRazon(){
    let cliId : string[] = [
        "BALL METAL BEVERAGE",
        "BALL TRADING",
    ];
    return cliId;
  }
  getTipOpe() : string[]{
      let TipOpe : string[] = [
        "IMPORTACION",
        "EXPORTACION",
    ];
    return TipOpe;
  }
  getAduana() : string[]{
      let aduanas : string []=[
        "240",
        "270",
        "800"
      ];
      return aduanas;
  }
  getPatente() : string[]{
    let patente : string []=[
      "3649"
    ];
    return patente;
}

  getValorInicial() :  ReportesEntity{

    let date: Date = new Date(); 

    let ParamsReport = new ReportesEntity();
    ParamsReport.ClaveCliente = "";
    ParamsReport.aduana = "270";
    ParamsReport.patente = "3649";
    ParamsReport.tipoOperacion = ""
    ParamsReport.FechaInicial = date;
    ParamsReport.FechaFinal = date;
    return ParamsReport;
  }
  GenerarExcel(ParametrosReporte :ReportesEntity): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type':  'application/json'
      })
    };
    var json = JSON.stringify(ParametrosReporte);
  
    return this.http.post(this.config.url+'/Reporte/ball/GenerarBallExcel', json, httpOptions).pipe(map(data => data));

  }
  download( filename: string): Observable<Blob> {
    console.log(filename);
    return this.http.get(this.config.url + '/Reporte/ball/DescargaBallExcel?fileName='+ filename,{
      responseType: 'blob'
    });
  }
}
