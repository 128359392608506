import { Component, OnInit,ViewChild , enableProdMode, ɵConsole } from '@angular/core';
import { FormGroup ,FormControl,FormBuilder, Validators } from '@angular/forms';
import Swal, { SweetAlertIcon } from 'sweetalert2'
import {LoginService} from '../../_services/login.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { CredencialesEntity } from './../../models/credenciales';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {
  loginForm : FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
   isLoadPanelVisible = false;
  constructor(
    private _credenciales : CredencialesEntity,
    private _LoginService: LoginService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private credenciales:CredencialesEntity
    ) {
      if (this.authenticationService.currentUserValue) { 
        this.router.navigate(['/home']);
    }
   }
    
  ngOnInit() {
       this.loginForm = this.formBuilder.group({
        Nombre: ['',Validators.required],
        Contrasena: ['',Validators.required]
       })
       this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.loginForm.controls; }

  onLoginClick (){
    this.isLoadPanelVisible =  true;
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.isLoadPanelVisible =  false;
        return;
    }

    this._credenciales.Nombre = this.f.Nombre.value;
    this._credenciales.Contrasena = this.f.Contrasena.value;

 
    this.authenticationService.login(this._credenciales).subscribe(
      result => {
        if(result.Status){
           //alert(result.Data.Menu);
            window.location.reload();
            this.router.navigate(['/home']);
            this.isLoadPanelVisible =  false;
         }else{
           this.showError("Error - Inicio de sesion",result.Message,"error");
           this.isLoadPanelVisible =  false;
         }
      },error =>{
        this.showError("Error - Inicio de sesion",error.Message,"error");
         console.log(<any>error);
         this.isLoadPanelVisible =  false;
      }
    )
  }
  showError(titleMsg:string,textMsg:string,iconMsg:SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }

}
