import { Component, OnInit,enableProdMode, ɵConsole } from '@angular/core';
import {  AuthenticationService } from './_services/authentication.service';
import { Router } from '@angular/router';
import { UsuariosEntity } from './models/Usuarios';
import { LoginComponent } from './pages/login/login.component';
import { AppService} from './_services/app.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MenuEntity } from './models/Menu';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AppService]
})
export class AppComponent implements OnInit {
  currentUser: UsuariosEntity;
  test: any;
  MenuItemsConfiguracion: Array<MenuEntity> = [];
  MenuItemsReportes: Array<MenuEntity> = [];
  MenuItemsExpedientes: Array<MenuEntity> = [];
  MenuItemsVistas: Array<MenuEntity> = [];
  UserOptions: string[];
  settingsButtonOptions: any;
  nombre : any;
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private appSer: AppService) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      this.UserOptions = this.appSer.getUsuariosOptions();
      locale("es");
     }
  ngOnInit() {
    if (this.currentUser.usuid === undefined){
      this.router.navigate(['/']);
    }else{
      this.nombre = this.currentUser.usunombre
    }
  }
  logout = (e) => {
    this.authenticationService.logout();
    this.router.navigate(['https://sit.tramitaciones.com']);
  }

}
