import { Component, OnInit, ViewChild, enableProdMode} from '@angular/core';
import { ReportesBodega } from '../../../models/ReportesBodega';
import {ServExtService } from '../../../_services/serv-ext.service';
import { DxRadioGroupModule, DxRadioGroupComponent, DxTemplateModule } from 'devextreme-angular';
import { ServicioExtra, FormCuenta} from '../../../models/ServicioExtra';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-serv-rpt',
  templateUrl: './serv-rpt.component.html',
  styleUrls: ['./serv-rpt.component.css'],
  providers:[ServExtService]
})
export class ServRptComponent implements OnInit {
  @ViewChild("eventRadioGroup", {static:false}) eventRadioGroup: DxRadioGroupComponent;
  busqueda: string[];
  parametros: ReportesBodega;
  data: Array<ServicioExtra> = [];
  traficos: Array<string> = [];
  mensaje: string;
  isErrorVisible:boolean;
  popupVisibleFacturados:boolean;
  datePipe:DatePipe;

  constructor(private _rpt : ServExtService) { 
    this.busqueda = [
        "Trafico", 
        "Fecha Alta", 
        "Fecha Cruce"
    ];
    this.parametros =  this._rpt.getValorReporte();
    this._rpt.selReporte(this.parametros);
    this._rpt.selTraficos().subscribe(
      result =>{
        if(result.Status){
          this.traficos =  result.Data;
        }
      }
    );
  }
  FormFac : FormCuenta = {
    id : 0, 
    formData : {
      Id:0,
      Facturas:"",
      Caja: "",
      Proveedor:"",
      Pedimento:"",
      Trafico:"",
      TipoOperacion:0,
      USD:0,
      MN:0,
      FechaDespacho:null,
      FacturasServExt:"",
      CuentaAmericana: "",
      Observaciones:"",
      IdInserto:0,
      FechaInserto: null,
      IdModifico:0,
      FechaModifico:null,
      Cliente:"",
      EstSerExtId:0,
      TipoOpe:"",
      FechaLlegada:null,
      Comentarios:"",
      Estatus:""

  }
};
  ngOnInit() {
  }
  ngAfterViewInit(){
    this.eventRadioGroup.instance.option("value", this.busqueda[2]);
}
  onValueChanged($event){ 
      switch($event.value){
        case "Trafico":this.parametros.TipoBusqueda = 1;break;
        case "Fecha Alta": this.parametros.TipoBusqueda = 2; this.parametros.Trafico=""; break;
        case "Fecha Cruce": this.parametros.TipoBusqueda = 3; this.parametros.Trafico=""; break;
      }
  }
  showData(e){
    this._rpt.selTrafico(e.values[1]).subscribe(
      result => {
        if(result.Status){
          this.FormFac.formData.Cliente =  result.Data[0].Cliente;
          this.FormFac.formData.Proveedor =  result.Data[0].Proveedor;
          this.FormFac.formData.Pedimento =  result.Data[0].Pedimento;
          this.FormFac.formData.FechaDespacho =  result.Data[0].FechaDespacho;
          this.FormFac.formData.FechaLlegada =  result.Data[0].FechaLlegada;
          this.FormFac.formData.Caja =  result.Data[0].Caja;
          this.FormFac.formData.Facturas =  result.Data[0].Facturas;
          this.FormFac.formData.FacturasServExt =  result.Data[0].FacturasServExt;
          this.FormFac.formData.Observaciones = result.Data[0].Observaciones;
          this.FormFac.formData.Comentarios =  result.Data[0].Comentarios;
          if(result.Data[0].TipoOperacion == 1){
            this.FormFac.formData.TipoOpe = "IMPORTACION";
          }else{
            this.FormFac.formData.TipoOpe = "EXPORTACION";
          }
          this.FormFac.formData.FechaModifico =  result.Data[0].FechaSincronizacion;
        }
      }
    );
    this.popupVisibleFacturados = true;

  }
  RefreshReport(){
    this.data = [];
    if(this.parametros.TipoBusqueda == 1 && this.parametros.Trafico == ""){
      this.showError("Error","Es necesario elegir el trafico.",'error');
    }else{
      this._rpt.selReporte(this.parametros).subscribe(
        result => {
          console.log(result);
          if(result.Status){
           
            this.data = result.Data;
            this.data.forEach(function(xData){
              if(xData.TipoOperacion == 1){
                xData.TipoOpe = "I";
              }else{
                xData.TipoOpe = "E";
              }
              switch(xData.EstSerExtId){ 
                case 1:xData.Estatus = "Por Facturar";break;
                case 2:xData.Estatus = "Facturado";break;
                case 3:xData.Estatus = "Por Liberar";break;
              }
            });
          }else{
            this.showError("No hay datos.",result.message,'error');
          }
        }
      );
    }
     
  }

  showError(titleMsg: string, textMsg: string, iconMsg: SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
  hidePop(){
    this.popupVisibleFacturados = false;
  }
  export(){
    let nameFile = "";
    if(this.parametros.TipoBusqueda == 1){
      nameFile = "Trafico " + this.parametros.Trafico+".xlsx";
    }
    if(this.parametros.TipoBusqueda == 2){
      nameFile = "Traficos  del "+this.parametros.FechaInicial.toDateString()+" hasta "+ this.parametros.FechaFinal.toDateString()+".xlsx";
    }
    if(this.parametros.TipoBusqueda == 3){
      nameFile = "Traficos  del "+this.parametros.FechaInicial.toDateString()+" hasta "+ this.parametros.FechaFinal.toDateString()+".xlsx";
    }
    if(this.parametros.TipoBusqueda == 1 && this.parametros.Trafico == ""){
      this.showError("Error","Es necesario elegir el trafico.",'error');
    }else{
      this._rpt.download(this.parametros).subscribe(
        blob => saveAs(blob, nameFile)
      )
    }
  }
}
