import { Component, OnInit, ɵConsole } from '@angular/core';
import {UsuariosService} from '../../_services/usuarios.service';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/interval';
import {GruposService} from '../../_services/grupos.service';
import { UsuariosEntity } from '../../models/Usuarios';
import {  AuthenticationService } from '../../_services/authentication.service';
import { ClientesService} from '../../_services/clientes.service';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers:[UsuariosService]
})
export class UsuariosComponent implements OnInit {
  public dataSource = [];
  public dsClientes = [];
  currentUser:UsuariosEntity;
  constructor(private _usuariosService : UsuariosService,
    private _clientes : ClientesService,
    private authenticationService: AuthenticationService ) {  
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  ngOnInit() {
      this.GetUsersDB();
      this._clientes.getClientesActivos()
  }
  GetUsersDB(){
    this._usuariosService.getUsers().subscribe(
      result => {
        if(result.Status){
          this.dataSource = result.Data;
        }else{
          this.showError('Error 1!',result,'error');
          return;
         // console.log(result);
        }
      },error =>{
        this.showError('Error! 2',<any>error,'error');
        return;
      }
    )
  }

  Insertar(e) {
    
    let itemsData = new  UsuariosEntity();
    itemsData.usuid = 0;
    itemsData.usunombre = e.data.usunombre;
    itemsData.usuusuario =  e.data.usuusuario;
    itemsData.usucorreo = e.data.usucorreo;
    itemsData.usuingreso = "";
    itemsData.usuexpiracion = "";
    itemsData.activo =  e.data.activo;
    itemsData.TipUsuId = 0;
    itemsData.GruId = e.data.GruId;
    this._usuariosService.addUsuario(this.currentUser.usuid, itemsData).subscribe(response => {
      if (response.Status) {
        this.GetUsersDB();
        this.showError('Error 1!',response.Message,'success');
      }else {
        this.GetUsersDB();
        this.showError('Error 2!',response.Message,'error');
      }
    },
    error => {
      this.showError('Error 3!',error,'error');
    });
    
  }

  showError(titleMsg:string,textMsg:string,iconMsg:SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
}
