import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';
import 'rxjs/add/operator/map';
import { UsuariosEntity} from '../models/Usuarios';
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http: HttpClient,private config:PeticionesService) { }

  getUsers(): Observable<any>{
    return this.http.get(this.config.url+"/Usuarios/GetAll");
  }
  addUsuario(usuid:number,Usuario: UsuariosEntity): Observable<any>{

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    var json = JSON.stringify(Usuario + usuid.toString());
    console.log(json);
    return this.http.post(this.config.url+'/Usuarios/Post?UsuModifica='+usuid, json, httpOptions).pipe(map(data => data));
  }
}
