import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {PeticionesService} from '../models/config-web-api';
import 'rxjs/add/operator/map';
import {} from '../models/Menu';
import { MenuEntity } from '../models/Menu';
import 'rxjs/add/operator/catch';
@Injectable()
export class AppService {

  constructor(private http: HttpClient, private config: PeticionesService) { }
  selMenu(usuid: number): Observable<any> {

    return this.http.get(this.config.url + '/MenuControlador/SelMenuControlador?usuid=' + usuid).pipe(map(data => data));
  }
  getUsuariosOptions(): any[] {
    const OpcionesUsuario =  [{ value: 1, name: 'Salir'}];
    return OpcionesUsuario;
  }
}
