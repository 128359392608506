import { Component, OnInit } from '@angular/core';
import { MenuEntity } from './../../models/Menu';
import { UsuariosEntity } from './../../models/Usuarios';
import {  AuthenticationService } from './../../_services/authentication.service';
import { Router } from '@angular/router';
import { AppService} from './../../_services/app.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [AppService]
})
export class NavbarComponent implements OnInit {
  test: any;
  menuEntityData: Array<MenuEntity> = [];
  currentUser: UsuariosEntity;
  showSubmenuModes: any;
  showFirstSubmenuModes: any;
  nombre : any;
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private appSer:AppService) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      this.showSubmenuModes = [{
          name: 'onHover',
          delay: { show: 0, hide: 500 }
      }, {
          name: 'onClick',
          delay: { show: 0, hide: 300 }
      }];
      this.showFirstSubmenuModes = this.showSubmenuModes[0];
    }
  ngOnInit() {
    if (this.currentUser) {
      this.updateMenu(this.currentUser.Menu);
    }
  }
  itemClick(data) {
    if (data.itemData.items == null) {
      this.router.navigate(['/' + data.itemData.id]);
    }
}
  updateMenu(menu: Array<MenuEntity>) {
    menu.forEach(
      data =>{
        let menuData  =  new MenuEntity();
        menuData  = data;
        this.menuEntityData.push(menuData);
      }
    );
    // this.appSer.selMenu(usuid).subscribe(
    //   result => {
    //     if (result.Status) {
    //       this.test = result.Data;
    //       this.test.forEach(data => {
           
    //       });
    //     } else {
    //       return;
    //     }
    //   }, error =>{
    //     console.log(<any>error);
    //     return;
    //   }
    // );
  }

}
