import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { SOIAImpoComponent} from './pages/soiaimpo/soiaimpo.component';
import { SOIAExpoComponent} from './pages/soiaexpo/soiaexpo.component';
import { AuthGuard } from './_helpers/auth.guard';
import { HomeComponent }  from './pages/home/home.component';
import { AppComponent } from './app.component';
import { ExpedientesComponent } from './pages/expedientes/expedientes.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { PlantillaBallComponent } from './pages/reportes/plantilla-ball/plantilla-ball.component';
import { PlantillaBremboComponent } from './pages/reportes/plantilla-brembo/plantilla-brembo.component';
import { BallConsolidadoComponent } from './pages/ball-consolidado/ball-consolidado.component';
import { ServExtComponent } from './pages/serv-ext/serv-ext.component';
import { ServRptComponent } from './pages/reportes/serv-rpt/serv-rpt.component';
const routes: Routes = [
    {path: '',component:HomeComponent,canActivate:[AuthGuard]},
    {path: 'login', component: LoginComponent  },
    {path: 'Expedientes',component:ExpedientesComponent},
    {path: 'ImpoSOIA', component:SOIAImpoComponent},
    {path: 'ExpoSOIA', component:SOIAExpoComponent},
    {path: 'home', component:HomeComponent},
    {path: 'Usuarios', component:UsuariosComponent},
    {path: 'PlantillaBall',component:PlantillaBallComponent},
    {path: 'PlantillaBrembo', component:PlantillaBremboComponent},
    {path: 'BallConsolidado', component:BallConsolidadoComponent},
    {path: 'ServiciosExtra',component:ServExtComponent},
    {path: 'ReporteSRV', component:ServRptComponent},
    { path: '**', redirectTo: ''}
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
