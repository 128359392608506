import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { ReporteBall } from '../models/ReportesEntity';
import { DatePipe } from '@angular/common' 
@Injectable({
  providedIn: 'root'
})
export class BallConsolidadoService {

  constructor(private http: HttpClient) { }


  getValorInicial() :  ReporteBall{

    let date: Date = new Date(); 

    let ParamsReport = new ReporteBall();
    ParamsReport.pedimento=  "";
    ParamsReport.FechaInicial = date;
    ParamsReport.FechaFinal = date;
    ParamsReport.Accepted = false;
    return ParamsReport;
  }
  getDataPorPedimento(pedimento:string,FechaInicial:string, FechaFinal:string, Accepted:boolean): Observable<any>{
    return this.http.get("http://slam.tramitaciones.com/apirest/api/Facturas/GetReporteBallSemanalConsolidado?pedimento="+pedimento+"&FechaInicial="+FechaInicial+"&FechaFinal="+FechaFinal+"&Accepted="+Accepted);
  }


  download( pedimento: string, FechaInicial:string, FechaFinal:string, Accepted:boolean): Observable<Blob> {
    return this.http.get('http://slam.tramitaciones.com/apirest/api/Facturas/DescargaBallExcel?pedimento='+ pedimento+"&FechaInicial="+FechaInicial+"&FechaFinal="+FechaFinal+"&Accepted="+Accepted,{
      responseType: 'blob'
    });
  }
}
