import { Component, OnInit, ViewChild    } from '@angular/core';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import {ExpedientesService} from '../../_services/expedientes.service';
import RemoteFileProvider from 'devextreme/file_management/remote_provider';
import {  AuthenticationService } from './../../_services/authentication.service';
import { UsuariosEntity } from './../../models/Usuarios';
import {DxFileManagerComponent,DxSelectBoxModule} from 'devextreme-angular';
import {FileItems } from './../../models/items';
import {FileItem } from './../../models/items';
import { saveAs } from 'file-saver';
import fileManager from 'devextreme/ui/file_manager';
import {PeticionesService} from '../../models/config-web-api';
import { SelectBoxFill } from '../../models/SelectBoxFill';
@Component({
  selector: 'app-expedientes',
  templateUrl: './expedientes.component.html',
  styleUrls: ['./expedientes.component.css'],
  providers: [ExpedientesService]
})
export class ExpedientesComponent implements OnInit {

  @ViewChild('fileManager', { static: false }) fileManager: DxFileManagerComponent
  currentUser: UsuariosEntity;
  fileItems: any[];
  allowedFileExtensions: string[];
  remoteProvider: RemoteFileProvider;
  dataFileManager : DxFileManagerComponent;
  selectItems: Array<FileItem> = [];
  itemsArray: any[];
  itemsFiles: FileItems;
  loadingVisible = false;
  downloadButton: any;
  mensajeLoad: string;
  selectItemClientes: Array<SelectBoxFill> = [];
  cliActual: number;
  searchModeOption: string = 'contains';
  searchExprOption: any = 'Name';
  searchTimeoutOption: number = 200;
  minSearchLengthOption: number = 0;
  filesIt: FileItems = new FileItems();

  constructor(private FilesExp: ExpedientesService, private authenticationService: AuthenticationService,
    private ServerOptions :PeticionesService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.allowedFileExtensions = ['.pdf', '.zip', '.xml', '.xlsx', '.xls', '.txt'];
    this.downloadButton = {
      icon: '../assets/download.svg',
      text: 'Descargar',
      onClick: () => {
          this.doneClick();
      }
    };
   }

  ngOnInit() {
    this.mensajeLoad = 'Cargando clientes...';
    this.loadingVisible = true;
    this.filesIt.usuId = this.currentUser.usuid;
    this.FilesExp.getClienteAdiconal(this.currentUser.usuid).subscribe(
      result => {
        if (result.Status) {
           this.selectItemClientes = result.Data;
           this.filesIt.cliId =  this.selectItemClientes[0].ID;
           this.remoteProvider = new RemoteFileProvider({
            endpointUrl: this.ServerOptions.url + '/File-manager-file-system-images?cliId=' + this.selectItemClientes[0].ID
          });
           this.loadingVisible = false;
        } else {
          this.loadingVisible = false;
        }
      }
      , err => {
        this.loadingVisible = false;
        this.showError('Error!' , err.Data, 'error');
      }
    );
  }
  onValueChanged(e) {
      this.cliActual = e.value;
      this.filesIt.cliId =  e.value
      this.remoteProvider = new RemoteFileProvider({
        endpointUrl: this.ServerOptions.url + '/File-manager-file-system-images?cliId=' + e.value
    });
  }
  doneClick() {
    this.loadingVisible = true;
    
    this.selectItems = [];
    this.itemsArray = this.fileManager.instance.getSelectedItems();
    this.itemsArray.forEach(data => {
      const itemFile  =  new FileItem();
      itemFile.nameItem = data.key;
      itemFile.idDirectory = data.isDirectory;
      this.selectItems.push(itemFile);
    });
    
    this.filesIt.listItems = this.selectItems;
    this.mensajeLoad = 'Calculando el total de su descarga..';
    if (this.selectItems.length > 0){
    this.FilesExp.downloadFilesZIP(this.filesIt).subscribe(
         result => {
           if (result.Status) {
            this.mensajeLoad = result.Message;
            this.FilesExp.DownloadFile(result.Data)
             .subscribe(
                 success => {
                     saveAs(success, result.Data);
                     this.loadingVisible = false;
                 },
                 err => {
                     this.loadingVisible = false;
                     this.showError('Error!' , err.Data, 'error');
                 }
             );
           } else {
            this.loadingVisible = false;
            this.showError('Error!', result.Data, 'error');
            return;
           }
         }, error => {
          this.loadingVisible = false;
          this.showError('Error!', 'Problemas al generar el archivo.', 'error');
          return;
    })
  } else{
    this.loadingVisible = false;
    this.showError('Error!', 'Se debe seleccionar por lo menos un archivo o carpeta.', 'error');
    }
  }
  customizeIcon(fileManagerItem) {
     if (fileManagerItem.isDirectory)
         return '../assets/folder.svg';
     const fileExtension = fileManagerItem.getFileExtension();
     switch (fileExtension) {
         case '.txt':
             return '../assets/folder.svg';
         case '.rtf':
             return 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/thumbnails/doc-rtf.svg';
         case '.xml':
             return 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/thumbnails/doc-xml.svg';
         case '.pdf':
            return '../assets/pdf.png';
         case '.zip':
           return '../assets/zip-format.svg';
          default:  return '../assets/paper.svg';
    }
}
  showError(titleMsg: string , textMsg: string , iconMsg: SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
}
