import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { PeticionesService } from '../models/config-web-api';
import 'rxjs/add/operator/map';
import {FileItems } from '../models/items';
@Injectable({
  providedIn: 'root'
})
export class ExpedientesService {
  constructor(private http: HttpClient,private config: PeticionesService) { }
  getClienteAdiconal(usuid: number): Observable<any> {
    return this.http.get(this.config.url + '/selClienteAdicional?usuid=' + usuid);
  }
  getExpediente(): Observable<any>{
    return this.http.get(this.config.url + '/Files/GST/GetFiles');
  }
  downloadFilesZIP(itemsFiles: FileItems): Observable<any>{

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    const json = JSON.stringify(itemsFiles);
    return this.http.post(this.config.url + '/Expedientes/DownloadItems2', json, httpOptions).pipe(map(data => data));
  }
  DownloadFile(filePath: string): Observable<any> {
    const input = filePath;

    return this.http.get(this.config.url + '/Expedientes/Expediente' + '?fileName=' + input, {
        responseType: 'blob',
        observe: 'response'
    })
        .pipe(
            map((res: any) => {
                return new Blob([res.body], { type: 'application/zip' });
            })
        );
}
}
