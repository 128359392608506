
export  class FileItems {
    usuId:number;
    cliId: number;
    listItems: Array<FileItem>;
}
export class FileItem{
    nameItem: string;
    idDirectory:boolean;
}
