import { InfoPedimentosImportacionEntity } from './InfoPedimentos';
import { InfoPedimentosExportacionEntity } from './InfoPedimentos';
export  class PedimentosBallImpoEntity {
    pedimento:string;
    ListPedimento:Array<InfoPedimentosImportacionEntity>;
}

export  class PedimentosBallExpoEntity {
    pedimento:string;
    ListPedimento:Array<InfoPedimentosExportacionEntity>;
}
