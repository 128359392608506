import { Component, OnInit } from '@angular/core';
import { SoiaimpoService } from '../../_services/soiaimpo.service'
import Swal, {SweetAlertIcon} from 'sweetalert2';
import {DxDataGridComponent} from 'devextreme-angular';
import { preserveWhitespacesDefault } from '@angular/compiler';
import { Observable,interval } from 'rxjs';
import 'rxjs/add/observable/interval';
@Component({
  selector: 'app-soiaimpo',
  templateUrl: './soiaimpo.component.html',
  styleUrls: ['./soiaimpo.component.css'],
  providers: [SoiaimpoService]
})
export class SOIAImpoComponent implements OnInit {
 // @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  public dataSource = [];
  constructor(private _SoiaImpoService : SoiaimpoService) { }

  ngOnInit() {
    this.GetImpoSoia();
    const timer = interval(15000);
    timer.subscribe(
      () => {
        this.GetImpoSoia();
       // console.log("Actualizado");
      });
  }
  GetImpoSoia(){
    this._SoiaImpoService.getInfoSoaiImportacion().subscribe(
      result => {
        //console.log(result);
        if(result.Status){
          this.dataSource = result.Data;
        }else{
          this.showError('Error 1!',result,'error');
          return;
         // console.log(result);
        }
      },error =>{
        this.showError('Error! 2',<any>error,'error');
        return;
      }
    )
  }
  onCellPrepared(e){
   // console.log(e);
    if(e.rowType === "data"  && e.column.dataField === "EstSoiDescripcion"){
      if(e.data.EstSoiDescripcion == "DESADUANADO/CUMPLIDO"){
        e.cellElement.style.background = "green";
      }
      if(e.data.EstSoiDescripcion == "CUMPLIDO"){
        e.cellElement.style.background = "green";
      }
      if(e.data.EstSoiDescripcion == "DESADUANADO"){
        e.cellElement.style.background = "green";
      }
      if(e.data.EstSoiDescripcion == "RECONOCIMIENTO ADUANERO"){
        e.cellElement.style.background = "red";
      }
      
    }
  }
  showError(titleMsg:string,textMsg:string,iconMsg:SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
}
