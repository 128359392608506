import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { PlantillaBallService } from '../../../_services/plantilla-ball.service';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/interval';
import {FormBuilder} from '@angular/forms';
import { ReportesEntity } from '../../../models/ReportesEntity';
import { PedimentosBallImpoEntity } from '../../../models/Pedimentos';
import { PedimentosBallExpoEntity } from '../../../models/Pedimentos';
import { InfoPedimentosImportacionEntity} from '../../../models/InfoPedimentos';
import { InfoPedimentosExportacionEntity} from '../../../models/InfoPedimentos';
import { saveAs } from 'file-saver';
import { DxSelectBoxModule,
  DxTextAreaModule,
  DxFormModule,
  DxFormComponent } from 'devextreme-angular';
@Component({
  selector: 'app-plantilla-ball',
  templateUrl: './plantilla-ball.component.html',
  styleUrls: ['./plantilla-ball.component.css'],
  providers: [PlantillaBallService]
})
export class PlantillaBallComponent implements OnInit {

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent;
  dataSource: any;
  dsInfo: any;
  searchForm: ReportesEntity;
  DataPedimentosImpo: Array<PedimentosBallImpoEntity> = [];
  DataPedimentosExpo: Array<PedimentosBallExpoEntity> = [];
  loading = false;
  submitted = false ;
  error = '';
  isLoadPanelVisible = false;
  isGridVisible: number;
  isData = false;
  TipOpe: string[];
  Patentes: string[];
  Aduanas: string[];
  filename: string;
  clientes: string[];

  buttonOptions: any = {
    text: 'Buscar',
    type: 'success',
    useSubmitBehavior: true
};
buttonExport: any = {
  text: 'Exportar XLSX',
  type: 'primary',
  useSubmitBehavior: true
};
  constructor(private _PlantillaBallService: PlantillaBallService,
      private ReportesParametros: ReportesEntity
      ,private formBuilder: FormBuilder) {
        this.TipOpe = this._PlantillaBallService.getTipOpe();
        this.Aduanas = this._PlantillaBallService.getAduana();
        this.Patentes = this._PlantillaBallService.getPatente();
        this.searchForm = this._PlantillaBallService.getValorInicial();
        this.clientes = this._PlantillaBallService.getBallRazon();
       }
       ngOnInit() {
        this.isGridVisible = 0;
      }
  showError(titleMsg: string, textMsg: string, iconMsg: SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
  onFormGetValues(e) {
    this.searchForm = e.component.option('formData');
 
  }
  export() {

    this.isLoadPanelVisible = true;
  
    this._PlantillaBallService.GenerarExcel(this.searchForm).subscribe(
      result =>{
        if (result.Status) {
          console.log(result.Status);
          this._PlantillaBallService.download(result.Data)
             .subscribe(blob => saveAs(blob, result.Data));
          this.isLoadPanelVisible = false;
        } else {
          this.isLoadPanelVisible = false;
          this.showError('Error', result.Message, 'error');
        }
      }
    );
  }
  onFormSubmit = function(e) {
    if (this.searchForm.Aduanas === '') {
      this.showError('Error', 'La aduana, es un dato necesario.', 'error');
    }
    if (this.searchForm.Patentes === '') {
      this.showError('Error', 'La patente, es un dato necesario.', 'error');
    }
    if (this.searchForm.tipoOperacion === '') {
      this.showError('Error', 'El tipo de operacion, es un dato necesario.', 'error');
    }
    if(this.searchForm.RazonSocial =="BALL METAL BEVERAGE")
    {
      this.searchForm.ClaveCliente = "BALL";
    }
    if(this.searchForm.RazonSocial == "BALL TRADING")
    {
      this.searchForm.ClaveCliente = "BALLTRADING";
    }
    if (this.searchForm.tipoOperacion === 'IMPORTACION') {
      if (this.searchForm.FechaFinal >=  this.searchForm.FechaInicial) {
        this.isLoadPanelVisible = true;
        this._PlantillaBallService.getReportesImportacion(this.searchForm).subscribe(
          result => {
            if (result.Status) {
              this.DataPedimentosImpo = [];
              this.dataSource = result.Data;
              let indez = 0;
              for (var index in this.dataSource) {
                  let pedInfo = new PedimentosBallImpoEntity();
                  this.dsInfo = this.dataSource[indez].ListPedimento;
                  pedInfo.pedimento= this.dataSource[indez].pedimento;
                  pedInfo.ListPedimento =  this.dsInfo;
                  this.DataPedimentosImpo.push(pedInfo);
                  indez++;
                }
                this.isLoadPanelVisible = false;
                this.isGridVisible = 1;
                this.isData = true;
            } else
            {
              this.showError('Error 5530!', result, 'error');
              this.isLoadPanelVisible = false;
              this.isData = false;
              return;
            }
          }, error =>{
            this.showError('Error! 5531', <any>error, 'error');
            this.isLoadPanelVisible = false;
            this.isData = false;
            return;
          }
        )
      } else
      {
        this.isLoadPanelVisible = false;
        this.showError('Error', 'La fecha inicial debe ser menor a la fecha final.', 'error');
      }
    }
    if(this.searchForm.tipoOperacion === 'EXPORTACION'){
      this.isLoadPanelVisible = true;
      if(this.searchForm.FechaFinal >  this.searchForm.FechaInicial){
        this._PlantillaBallService.getReportesExportacion(this.searchForm).subscribe(
          result => {
            if (result.Status) {
              this.DataPedimentosExpo = [];
              this.dataSource = result.Data;
              let indez = 0;
              for (var index in this.dataSource) {
                  let pedInfo = new PedimentosBallExpoEntity();
                  this.dsInfo = this.dataSource[indez].ListPedimento;
                  pedInfo.pedimento= this.dataSource[indez].pedimento;
                  pedInfo.ListPedimento =  this.dsInfo;
                  this.DataPedimentosExpo.push(pedInfo);
                  indez++;
                }
                this.isLoadPanelVisible = false;
                this.isGridVisible = 2;
                this.isData = true;
            } else{
              this.showError('Error 1!', result, 'error');
              this.isLoadPanelVisible = false;
              this.isData = false;
              return;
            }
          }, error =>{
            this.showError('Error! 2', <any>error, 'error');
            this.isLoadPanelVisible = false;
            this.isData = false;
            return;
          }
        )
      } else
      {
        this.isLoadPanelVisible = false;
        this.showError('Error', 'La fecha inicial debe ser menor a la fecha final.', 'error');
      }
    }
  }
}
