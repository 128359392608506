import { Download } from './../../../models/download';
import { Component, OnInit,ViewChild } from '@angular/core';
import {PlantillaImpoEncabezado
  ,PlantillaImpoDetalle
  ,PlantillaExpoEncabezado
  ,PlantillaExpoDetalle
  ,MiaExpoDetalle
  ,MiaExpoEncabezado
  ,MiaImpoDetalle
  ,MiaImpoEncabezado,
  ResultPlantilasBrembo} from '../../../models/PlantillaBrembo';
import {ReportesEntity} from '../../../models/ReportesEntity';
import { saveAs } from 'file-saver';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { DxFormComponent } from 'devextreme-angular';
import { PlantillaBremboService } from '../../../_services/plantilla-brembo.service';
@Component({
  selector: 'app-plantilla-brembo',
  templateUrl: './plantilla-brembo.component.html',
  styleUrls: ['./plantilla-brembo.component.css'],
  providers: [PlantillaBremboService]
})
export class PlantillaBremboComponent implements OnInit {

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent;

  constructor(private _PlantillaBremboServices: PlantillaBremboService)
  {
    this.Aduanas = this._PlantillaBremboServices.getAduana();
    this.searchForm = this._PlantillaBremboServices.getValorInicial();
  }
  DataPlantillaImpoEncabezado: Array<PlantillaImpoEncabezado> = [];
  DataPlantillaImpoDetalle: Array<PlantillaImpoDetalle> = [];
  DataPlantillaExpoEncabezado: Array<PlantillaExpoEncabezado> = [];
  DataPlantillaExpoDetalle: Array<PlantillaExpoDetalle> = [];
  DataMiaExpoDetalle: Array<MiaExpoDetalle> = [];
  DataMiaExpoEncabezado: Array<MiaExpoEncabezado> = [];
  DataMiaImpoDetalle: Array<MiaImpoDetalle> = [];
  DataMiaImpoEncabezado: Array<MiaImpoEncabezado> = [];
  isLoadPanelVisible = false;
  Aduanas: string[];
  submitted = false ;
  searchForm: ReportesEntity;
  datasource: any;
  resultPlantilla: Array<ResultPlantilasBrembo> = [];
  mensajeLoad: string;

  buttonOptions: any = {
    text: 'Buscar',
    type: 'success',
    useSubmitBehavior: true
  };
  ngOnInit() {
  }

  onFormGetValues(e)
  {
    this.searchForm = e.component.option('formData');
  }
  showError(titleMsg: string, textMsg: string, iconMsg: SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }
  onFormSubmit = function(e) {
    if (this.searchForm.Aduanas === '')
    {
      this.showError('Error', 'La aduana, es un dato necesario.', 'error');
    }
    if(this.searchForm.FechaFinal >=  this.searchForm.FechaInicial) {
      this.isLoadPanelVisible = true;
      this.mensajeLoad = 'Generando plantillas...';
      this._PlantillaBremboServices.getPLantillaBrembo(this.searchForm).subscribe(
        result => {
          if (result.Status) {
            this._PlantillaBremboServices.download(result.Data)
            .subscribe(blob => saveAs(blob, 'PLANTILLAS BREMBO.zip'));
            this.isLoadPanelVisible = false;
          } else {
            this.isLoadPanelVisible = false;
            this.showError('Error', 'No hay registros, intente con una fecha o aduana.', 'error');
          }
        }
      );

    } else {
      this.isLoadPanelVisible = false;
      this.showError('Error', 'La fecha final debe ser mayor a la fecha inicial.', 'error');
    }
  }
}