import { Component, OnInit } from '@angular/core';
import Swal, {SweetAlertIcon} from 'sweetalert2';
import { SoiaexpoService } from '../../_services/soiaexpo.service';
import { Observable, interval } from 'rxjs';
@Component({
  selector: 'app-soiaexpo',
  templateUrl: './soiaexpo.component.html',
  styleUrls: ['./soiaexpo.component.css'],
  providers: [SoiaexpoService]
})
export class SOIAExpoComponent implements OnInit {
  public dataSource = [];
  constructor(private _SoiaexpoService : SoiaexpoService) { }

  ngOnInit() {
      this.GetExpoSoia();
      const timer = interval(15000);
      timer.subscribe(
        () => {
          this.GetExpoSoia();
          //console.log("Actualizado");
        });
  }
  GetExpoSoia(){
    this._SoiaexpoService.getInfoSoaiExportacion().subscribe(
      result => {
       // console.log(result);
        if(result.Status){
          this.dataSource = result.Data;
        }else{
          this.showError('Error 1!',result,'error');
          return;
         // console.log(result);
        }
      },error =>{
        this.showError('Error! 2',<any>error,'error');
        return;
      }
    )
  }
  onCellPrepared(e){
    // console.log(e);
     if(e.rowType === "data"  && e.column.dataField === "EstSoiDescripcion"){
       if(e.data.EstSoiDescripcion == "DESADUANADO/CUMPLIDO"){
         e.cellElement.style.background = "green";
       }
       if(e.data.EstSoiDescripcion == "CUMPLIDO"){
         e.cellElement.style.background = "green";
       }
       if(e.data.EstSoiDescripcion == "DESADUANADO"){
         e.cellElement.style.background = "green";
       }
       if(e.data.EstSoiDescripcion == "RECONOCIMIENTO ADUANERO"){
         e.cellElement.style.background = "red";
       }
       
     }
   }
  showError(titleMsg:string,textMsg:string,iconMsg:SweetAlertIcon){
    Swal.fire({
      title: titleMsg,
      text: textMsg,
      icon: iconMsg
    });
  }

}
